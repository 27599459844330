interface NavigationsState {
       dashboard_side_menu:object[],
}

export const useNavigations = defineStore('navigations', {
    state: (): NavigationsState => {
        return {
            dashboard_side_menu:
                [
                    {
                    title: "Islamic Centers",
                    isAccordianOn: true,
                    subMenuItems: [
                        {
                            title: "My Islamic Center Listings",
                            link: {
                                routeName: "dashboard-islamic-centers",
                                queryParams: {
                                    filter: "active",
                                },
                            },
                            icon: "streamline:religion-islam-religion-islam-moon-crescent-muslim-culture-star",
                        },
                        {
                            title: "Subscribed Islamic Centers",
                            link: {
                                routeName:
                                    "dashboard-islamic-centers-subscribed-islamic-centers",
                            },
                            icon: "streamline:rss-symbol",
                        },
                    ],
                },
                    {
                        title: "Businesses",
                        isAccordianOn: true,
                        subMenuItems: [
                            {
                                title: "My Business Listings",
                                link: {
                                    routeName: "dashboard-businesses",
                                    queryParams: {
                                        filter: "active",
                                    },
                                },
                                icon: "streamline:business-card",
                            },
                            {
                                title: "Liked Businesses",
                                link: {
                                    routeName:
                                        "dashboard-businesses-liked-businesses",
                                },
                                icon: "streamline:like-1",
                            },
                            {
                                title: "My Business Reviews",
                                link: {
                                    routeName:
                                        "dashboard-businesses-reviews",
                                },
                                icon: "streamline:interface-favorite-star-reward-rating-rate-social-star-media-favorite-like-stars",
                            },
                            {
                                title: "Availed Coupons",
                                link: {
                                    routeName:
                                        "dashboard-businesses-availed-coupons",
                                    queryParams: {
                                        filter: "active",
                                    },
                                },
                                icon: "streamline:discount-percent-coupon",
                            },
                        ],
                    },
        {
            title: "Classified",
                isAccordianOn: true,
            subMenuItems: [
            {
                title: "My Classified Ads",
                link: {
                    routeName:
                        "dashboard-classified",
                    queryParams: {
                        filter: "active",
                    },
                },
                icon: "streamline:interface-content-chart-product-data-analysis-analytics-graph-line-business-board-chart",
            },
            {
                title: "Saved Ads",
                link: {
                    routeName:
                        "dashboard-classified-saved-ads",
                },
                icon: "streamline:computer-storage-floppy-disk-disk-floppy-electronics-device-disc-computer",
            },
        ],
        },
        {
            title: "Fundraising",
                isAccordianOn: true,
            subMenuItems: [
            {
                title: "My Fundraisers",
                link: {
                    routeName:
                        "dashboard-fundraising",
                    queryParams: {
                        filter: "active",
                    },
                },
                icon: "streamline:health-medical-heart-rate-health-beauty-information-data-beat-pulse-monitor-heart-rate-info",
            },
            {
                title: "Saved Fundraisers",
                link: {
                    routeName:
                        "dashboard-fundraising-saved-campaigns",
                },
                icon: "streamline:hearts-symbol",
            },
            {
                title: "My Donations",
                link: {
                    routeName:
                        "dashboard-fundraising-my-donations",
                },
                icon: "streamline:interface-favorite-give-heart-reward-social-rating-media-heart-hand",
            },
        ],
        },
        {
            title: "News",
                isAccordianOn: true,
            subMenuItems: [
            {
                title: "My Announcements",
                link: {
                    routeName:
                        "dashboard-news",
                    queryParams: {
                        filter: "active",
                    },
                },
                icon: "streamline:interface-share-mega-phone-1-bullhorn-loud-megaphone-share-speaker-transmit",
            },
            {
                title:
                    "Pinned News & Announcements",
                link: {
                    routeName:
                        "dashboard-news-pinned-news",
                },
                icon: "streamline:interface-edit-pin-2-pin-push-thumbtack",
            },
        ],
        },
    ]
        }
    },
});